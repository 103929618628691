import React from 'react'
import { Box, Typography, LinearProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Sticky from 'react-sticky-el'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  progress: {
    background: theme.palette.background.default,
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  root: {
    height: 30,
    borderRadius: 10,
  },
  container: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
  },
  text: {
    display: 'flex',
    justifyContent: 'center',
    height: 'inherit',
    alignItems: 'center',
  },
}))
const SurveyProgress = ({ total, availableCredits, preferredToken }) => {
  const classes = useStyles()
  const progress = Math.floor((availableCredits / total) * 100)

  return (
    <Sticky topOffset={75} stickyStyle={{ zIndex: 1 }}>
      <Box className={classes.progress} width="100%">
        <Box flexGrow={1} position="relative">
          <LinearProgress className={classes.root} variant="determinate" value={progress} />

          <Box className={classes.container}>
            <Box className={classes.text}>
              <Typography variant="h6" color="textSecondary">
                {availableCredits}/{total} {preferredToken}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Sticky>
  )
}

SurveyProgress.propTypes = {
  total: PropTypes.string.isRequired,
  availableCredits: PropTypes.number.isRequired,
  preferredToken: PropTypes.string.isRequired,
}

export default SurveyProgress
