import React from 'react'
import ProgressBar from 'components/ProgressBar'

const ReAuthenticate = () => {
  React.useEffect(() => {
    window.location.assign(window.location)
  }, [])

  return <ProgressBar active />
}

export default ReAuthenticate
