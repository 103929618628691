import React, { createContext, useReducer } from 'react'
import PropTypes from 'prop-types'

const lsThemeKey = '__qvsr-theme__'
const lsModeView = '__qvsr-view-mode__'
const initialState = {
  viewMode: 'widget',
  theme: 'light',
}
const UserPreferencesContext = createContext(initialState)
const userPreferencesReducer = (state, { type }) => {
  switch (type) {
    case 'toggleTheme': {
      const theme = state.theme === 'dark' ? 'light' : 'dark'

      window.localStorage.setItem(lsThemeKey, theme)

      return {
        ...state,
        theme,
      }
    }

    case 'toggleViewMode': {
      const newViewMode = state.viewMode === 'widget' ? 'list' : 'widget'

      window.localStorage.setItem(lsModeView, newViewMode)

      return {
        ...state,
        viewMode: newViewMode,
      }
    }

    default:
      return state
  }
}

const UserPreferencesProvider = ({ children }) => {
  const theme = window.localStorage.getItem(lsThemeKey) || initialState.theme
  const viewMode = window.localStorage.getItem(lsModeView) || initialState.viewMode
  const [preferences, dispatch] = useReducer(userPreferencesReducer, { theme, viewMode })
  const value = { preferences, dispatch }

  return <UserPreferencesContext.Provider value={value}>{children}</UserPreferencesContext.Provider>
}

UserPreferencesProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

const UserPreferencesContexConsumer = UserPreferencesContext.Consumer

export { userPreferencesReducer, UserPreferencesContext, UserPreferencesProvider, UserPreferencesContexConsumer }
