import React from 'react'
import { Box, Typography, Button, Container } from '@material-ui/core'
import PropTypes from 'prop-types'
import { createAnswer } from 'util/survey-client'
import useAsync from 'util/use-async'
import ActionConfirmDialog from 'components/ActionConfirmDialog'
import { makeStyles } from '@material-ui/core/styles'
import SurveyProgress from '../components/Progress'
import Vote from '../components/Vote'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  submitButton: {
    width: 250,
    height: 55,
  },
}))
const Questions = ({
  questions,
  surveyId,
  researcherId,
  vote,
  onNext,
  canVote,
  previewMode,
  config,
  availableCredits,
  status,
  isLastStep,
  userId,
  pageLoadsAt,
  questionsPageLoadAt,
}) => {
  const { run, isSuccess, isLoading } = useAsync()
  const preferredToken = config.preferredToken === 'custom' ? config.customPreferredToken : config.preferredToken
  const [open, setOpen] = React.useState(false)
  const classes = useStyles()
  const [submited, setSubmited] = React.useState(false)
  const [startTime, setStartTime] = React.useState(null)

  React.useEffect(() => {
    if (submited) {
      if (config.qualtricsIntegration) {
        let qParams = ''

        console.log('questions', questions)

        questions.forEach((q) => {
          if (qParams.length > 0) {
            qParams = `${qParams}&${q.id}=${q.vote}`
          } else {
            qParams = `${q.id}=${q.vote}`
          }
        })

        console.log('qParams', qParams)

        window.open(`${config.qualtricsLink}?${qParams}&credrem=${availableCredits}&userId=${userId}`, '_self')
      } else if (isLastStep) {
        window.location.assign(window.location)
      }
    }
  }, [availableCredits, config.qualtricsIntegration, config.qualtricsLink, isLastStep, questions, submited, userId])

  React.useEffect(() => {
    if (availableCredits !== parseInt(config.credits)) {
      setStartTime((oldValue) => {
        return oldValue === null ? new Date().toISOString() : oldValue
      })
    }
  }, [availableCredits, config.credits, vote])

  function handleSaveSurvey() {
    const answer = {
      surveyId,
      researcherId,
      surveyStatus: status.toLowerCase(),
      questions,
      pageLoadsAt,
      startTime,
      endTime: new Date().toISOString(),
      availableCredits,
      questionsPageLoadAt: questionsPageLoadAt || pageLoadsAt,
    }

    if (userId) {
      answer.userId = userId
    }

    run(createAnswer(answer), true)
  }

  function handleSubmit() {
    if (availableCredits > 0) {
      setOpen(true)
    } else {
      handleSaveSurvey()
    }
  }

  React.useEffect(() => {
    if (isSuccess) {
      // TODO: maybe we should move this logic to a util file

      // add taken survey to localstorage
      if (status === 'Published') {
        const takenSurveys = JSON.parse(window.localStorage.getItem('__qvsr_taken_surveys__')) || []

        takenSurveys.push(surveyId)
        window.localStorage.setItem('__qvsr_taken_surveys__', JSON.stringify(takenSurveys))
      }

      setSubmited(true)
      onNext()
    }
  }, [isSuccess, onNext, status, surveyId])

  return (
    <Container maxWidth="md">
      <Box className={classes.container}>
        <Box hidden={previewMode}>
          <SurveyProgress total={config.credits} availableCredits={availableCredits} preferredToken={preferredToken} />
        </Box>

        <Box display="flex" flexDirection="column" flexGrow={1}>
          <Box flexGrow={1}>
            <Box display="flex" flexDirection="column" alignItems="center">
              {questions.map((question, index) => (
                <Box my={2} key={question.id}>
                  <Typography variant="h6">{`${index + 1}. ${question.label}`}</Typography>
                  <Box display="flex" justifyContent="center">
                    <Vote
                      question={question}
                      vote={vote}
                      canVote={canVote}
                      questionIndex={index}
                      thumbsUpLanguage={config.thumbsUpLanguage}
                      thumbsDownLanguage={config.thumbsDownLanguage}
                      preferredToken={preferredToken}
                      totalCredits={config.credits}
                      preferredFunction={config.preferredFunction}
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>

        {!previewMode && (
          <Box display="flex" alignItems="center" flexDirection="column" my={5}>
            <Button
              color="primary"
              variant="contained"
              onClick={handleSubmit}
              className={classes.submitButton}
              disabled={isLoading || availableCredits === config.credits || submited}
            >
              Submit
            </Button>
          </Box>
        )}
      </Box>

      <ActionConfirmDialog
        open={open}
        handleClose={() => setOpen(false)}
        action={handleSaveSurvey}
        confirmText={`You have ${availableCredits} credit left! Do you still want to submit your answer?`}
        title="Submit"
        buttonText="Submit"
      />
    </Container>
  )
}

Questions.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.any).isRequired,
  surveyId: PropTypes.string,
  researcherId: PropTypes.string,
  vote: PropTypes.func.isRequired,
  onNext: PropTypes.func,
  canVote: PropTypes.func.isRequired,
  previewMode: PropTypes.bool,
  status: PropTypes.string,
  isLastStep: PropTypes.bool,
  pageLoadsAt: PropTypes.objectOf(PropTypes.any),
  questionsPageLoadAt: PropTypes.objectOf(PropTypes.any),
  config: PropTypes.objectOf(PropTypes.any).isRequired,
  availableCredits: PropTypes.number.isRequired,
  userId: PropTypes.string,
}

Questions.defaultProps = {
  researcherId: null,
  userId: null,
  pageLoadsAt: null,
  onNext: () => {},
  previewMode: false,
  isLastStep: false,
  questionsPageLoadAt: null,
  status: null,
  surveyId: null,
}

export default Questions
