const localStorageKey = '__qvsr__'

async function client(endpoint, { body, ...customConfig } = {}) {
  const token = window.localStorage.getItem(localStorageKey)
  const headers = { 'content-type': 'application/json' }

  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  const config = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
      userid: window.localStorage.getItem(localStorageKey),
    },
    credentials: 'include',
  }

  if (body) {
    config.body = JSON.stringify(body)
  }

  /* eslint-disable consistent-return */
  return window.fetch(`${process.env.REACT_APP_FIREBASE_FUNCTION_API}/${endpoint}`, config).then(async (response) => {
    if (response.status === 401) {
      window.location.assign(window.location)

      return
    }

    const data = await response.json()

    if (response.ok) {
      return data
    }

    removeToken()

    return Promise.reject(data)
  })
  /* eslint-enable consistent-return */
}

function setToken({ token }) {
  window.localStorage.setItem(localStorageKey, token)
}

function removeToken() {
  window.localStorage.removeItem(localStorageKey)
}

function hasToken() {
  return !!window.localStorage.getItem(localStorageKey)
}

export { client, setToken, removeToken, hasToken, localStorageKey }
