import React, { createContext, useReducer } from 'react'
import VideoLabelIcon from '@material-ui/icons/VideoLabel'
import BuildIcon from '@material-ui/icons/Build'
import PostAddIcon from '@material-ui/icons/PostAdd'
import PropTypes from 'prop-types'

import SurveyConfigForm from 'components/forms/CreateSurvey/components/SurveyConfig'
import SurveyQuestionsForm from 'components/forms/CreateSurvey/components/SurveyQuestions'
import SurveyPreviewForm from 'components/forms/CreateSurvey/components/SurveyPreview'

const initialState = {
  steps: [
    { desc: 'Configuration', component: SurveyConfigForm, icon: BuildIcon },
    { desc: 'Survey', component: SurveyQuestionsForm, icon: PostAddIcon },
    { desc: 'Preview', component: SurveyPreviewForm, icon: VideoLabelIcon },
  ],
  config: {
    topic: '',
    preferredFunction: '',
    credits: '',
    qualtricsIntegration: false,
    preferredLanguage: 'agree/disagree',
    thumbsUpLanguage: '',
    thumbsDownLanguage: '',
    preferredToken: 'Credits',
    customPreferredToken: '',
  },
  setup: {
    questions: [
      { value: '', id: 0 },
      { value: '', id: 1 },
      { value: '', id: 2 },
    ],
    welcomeMessage: '',
    completionMessage: '',
  },
  completedSteps: [0],
}
const CreateSurveyContext = createContext(initialState)
const createSurveyReducer = (state, { type, value }) => {
  switch (type) {
    case 'new':
      return {
        ...initialState,
      }

    case 'update': {
      // this logic is here for now, but we have to think a better way for this
      if (value.config) {
        const { preferredLanguage } = value.config
        const capitalize = (s) => {
          if (typeof s !== 'string') return ''

          return s.charAt(0).toUpperCase() + s.slice(1)
        }

        if (preferredLanguage && preferredLanguage !== 'custom') {
          const separation = preferredLanguage.indexOf('/')

          value.config.thumbsUpLanguage = capitalize(preferredLanguage.substring(0, separation))
          value.config.thumbsDownLanguage = capitalize(
            preferredLanguage.substring(separation + 1, preferredLanguage.length),
          )
        }

        value.config.customPreferredToken =
          value?.config?.preferredToken !== 'custom' ? '' : value.config.customPreferredToken
      }

      return {
        ...state,
        ...value,
        completedSteps: [...state.completedSteps, value.stepIndex],
      }
    }

    default:
      return state
  }
}

const CreateSurveyProvider = ({ children }) => {
  const [survey, dispatch] = useReducer(createSurveyReducer, initialState)
  const value = { survey, dispatch }

  return <CreateSurveyContext.Provider value={value}>{children}</CreateSurveyContext.Provider>
}

CreateSurveyProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

const CreateSurveyContexConsumer = CreateSurveyContext.Consumer

export { createSurveyReducer, CreateSurveyContext, CreateSurveyProvider, CreateSurveyContexConsumer }
