import * as auth from 'util/auth-client'

async function bootstrapAppData() {
  let appData = { user: null }

  if (auth.isLoggedIn()) {
    const [user] = await Promise.all([auth.getUser()])

    appData = { user }
  }

  return appData
}

export default bootstrapAppData
