import React from 'react'
import { Box } from '@material-ui/core'
import { useAuth } from 'context/auth-context'

// TODO: study mode other modalities of lazy loading
// TODO: study wha is webpackPrefetch
const AuthenticatedApp = React.lazy(() => import(/* webpackPrefetch: true */ 'AuthenticatedApp'))
const UnauthenticatedApp = React.lazy(() => import(/* webpackChunkName: "UnauthenticatedApp" */ 'UnauthenticatedApp'))
const Routes = () => {
  const { user } = useAuth()

  return (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </Box>
  )
}

export default Routes
