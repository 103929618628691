import React from 'react'
import { Box, Button, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'

const ErrorFallback = ({ error, componentStack, resetErrorBoundary }) => {
  return (
    <Box role="alert">
      <Typography variant="h5">Something went wrong:</Typography>
      <pre>{error.message}</pre>
      <pre>{componentStack}</pre>
      <Button onClick={resetErrorBoundary}>Try again</Button>
    </Box>
  )
}

ErrorFallback.propTypes = {
  error: PropTypes.objectOf(PropTypes.any).isRequired,
  componentStack: PropTypes.objectOf(PropTypes.any).isRequired,
  resetErrorBoundary: PropTypes.func.isRequired,
}

export default ErrorFallback
