import React from 'react'
import { AuthProvider } from 'context/auth-context'
import { SharedProvider } from 'context/shared-context'
import { CreateSurveyProvider } from 'context/create-survey-context'
import PropTypes from 'prop-types'

function AppProviders({ children }) {
  return (
    <AuthProvider>
      <SharedProvider>
        <CreateSurveyProvider>{children}</CreateSurveyProvider>
      </SharedProvider>
    </AuthProvider>
  )
}

AppProviders.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AppProviders
