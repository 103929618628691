import React from 'react'
import { useForm, useFieldArray } from 'react-hook-form'
import { Box, Grid, Container, Button, Typography, TextField, IconButton, Snackbar } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import WarningIcon from '@material-ui/icons/Warning'
import ClearIcon from '@material-ui/icons/Clear'
import PropTypes from 'prop-types'
import RichTextEditor from 'components/RichTextEditor'

const useStyles = makeStyles((theme) => ({
  addQuestion: {
    border: `dashed 4px ${theme.palette.grey[700]}`,
    height: theme.spacing(7),
    color: ({ fields }) => (fields.length >= 10 ? 'orange' : theme.palette.grey[700]),
    '&:hover': {
      color: theme.palette.text.primary,
      border: `dashed 4px ${theme.palette.text.primary}`,
    },
    [theme.breakpoints.down('sm')]: {
      '&&': {
        color: theme.palette.text.primary,
        border: `dashed 4px ${theme.palette.text.primary}`,
      },
    },
  },
}))
const SurveyQuestion = ({ handleSave, handleBack, survey }) => {
  const { register, handleSubmit, control, watch, setValue, errors } = useForm({
    defaultValues: survey.setup,
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'questions',
  })
  const classes = useStyles({ fields })
  const [warning, setWarning] = React.useState(false)
  const [welcomeMessage, setWelcomeMessage] = React.useState(survey.setup.welcomeMessage)
  const [completionMessage, setCompleetionMessage] = React.useState(survey.setup.welcomeMessage)
  const onSubmit = (setup) => {
    handleSave({
      setup: {
        ...setup,
        welcomeMessage,
        completionMessage,
      },
      stepIndex: 2,
    })
  }

  React.useEffect(() => {
    if (fields.length === 10) {
      setWarning(true)
    } else {
      setWarning(false)
    }
  }, [fields.length])

  React.useEffect(() => {
    register('welcomeMessage')
    register('completionMessage')
  }, [register])

  function handleWelcomeMsgChange(text) {
    setWelcomeMessage(text)
  }

  function handleCompletionMsgChange(text) {
    setCompleetionMessage(text)
  }

  function handleAddQuestion() {
    append({ name: 'questions', value: '' })
  }

  return (
    <Container maxWidth="sm">
      <form onSubmit={(e) => e.preventDefault()}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box mb={2} mt={4}>
              <Typography variant="h5" color="textSecondary">
                <Typography component="span" variant="h5" color="primary">
                  #3
                </Typography>
                {'  '}
                Welcome message
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <RichTextEditor handleChange={handleWelcomeMsgChange} initialValue={survey.setup.welcomeMessage} />
          </Grid>

          <Grid item xs={12}>
            <Box mb={2} mt={4}>
              <Typography variant="h5" color="textSecondary">
                <Typography component="span" variant="h5" color="primary">
                  #4
                </Typography>
                {'  '}
                Questions
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} container>
            {fields.map((item, index) => {
              const quest = watch(`questions[${index}]`)
              // TODO: shitty solution, blocks me to scale and add new functions
              const questionId = survey.config.preferredFunction === 'Linear' ? 'L' : 'QV'
              let hasError = false
              let errorMessage = ''

              if (errors?.questions) {
                hasError = errors.questions[index]?.value
                errorMessage = errors.questions[index]?.value.message
              }

              return (
                <Grid item xs={12} key={item.id}>
                  <Box mt={index > 0 ? 2 : 0} my={2}>
                    <TextField
                      variant="outlined"
                      name={`questions[${index}].value`}
                      defaultValue={item.value}
                      label="Question"
                      fullWidth
                      required
                      autoFocus
                      autoComplete="off"
                      helperText={
                        <Box component="span" display="flex" justifyContent="space-between">
                          <Box component="span">
                            <Typography variant="caption">{errorMessage || `${questionId}${index + 1}`}</Typography>
                          </Box>
                        </Box>
                      }
                      inputRef={register({
                        required: 'Please enter question',
                      })}
                      error={hasError}
                      InputProps={{
                        endAdornment: (
                          <>
                            {quest?.value?.length > 0 && (
                              <IconButton color="secondary" onClick={() => setValue(`questions[${index}].value`, '')}>
                                <ClearIcon />
                              </IconButton>
                            )}
                            <IconButton color="secondary" onClick={() => remove(index)} disabled={fields.length <= 2}>
                              <DeleteIcon />
                            </IconButton>
                          </>
                        ),
                      }}
                    />
                  </Box>
                </Grid>
              )
            })}
          </Grid>

          <Grid item xs={12}>
            <Box my={2}>
              <Button
                variant="outlined"
                fullWidth
                className={classes.addQuestion}
                onClick={handleAddQuestion}
                startIcon={fields.length >= 10 && <WarningIcon />}
              >
                Add Question
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box mb={2} mt={4}>
              <Typography variant="h5" color="textSecondary">
                <Typography component="span" variant="h5" color="primary">
                  #5
                </Typography>
                {'  '}
                Completion message
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <RichTextEditor handleChange={handleCompletionMsgChange} initialValue={survey.setup.completionMessage} />
          </Grid>

          <Grid item xs={12}>
            <Box mt={5} mb={7} display="flex" justifyContent="space-between">
              <Button variant="outlined" onClick={handleBack}>
                Back
              </Button>
              <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
                Next
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={warning}
        autoHideDuration={5000}
        onClose={() => setWarning(false)}
        action={
          <>
            <Typography variant="body1">We recommend to have maximum of 10 questions</Typography>
            <Button color="secondary" size="small" onClick={() => setWarning(false)}>
              close
            </Button>
          </>
        }
      />
    </Container>
  )
}

SurveyQuestion.propTypes = {
  handleSave: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  survey: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default SurveyQuestion
