import Quadratic from './quadratic'
import Linear from './linear'

const useMethod = (survey) => {
  const { preferredFunction } = survey.config
  const functions = {
    Quadratic,
    Linear,
  }

  return functions[preferredFunction](survey)
}

export default useMethod
