import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import {
  Box,
  Grid,
  Button,
  TextField,
  Container,
  MenuItem,
  Typography,
  FormControl,
  InputLabel,
  Select,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  IconButton,
  Tooltip,
  ClickAwayListener,
} from '@material-ui/core'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import PropTypes from 'prop-types'
import InfoIcon from '@material-ui/icons/Info'

const SurveyConfig = ({ handleSave, survey }) => {
  const [openTooltip, setTooltip] = React.useState(false)
  const { register, handleSubmit, control, watch, errors, setValue } = useForm({
    defaultValues: survey.config,
  })
  const { status } = survey
  const onSubmit = (config) => {
    // disabled fields does not appear by default
    Object.keys(config).forEach((key) => config[key] === undefined && delete config[key])

    handleSave({
      config: {
        ...survey.config,
        ...config,
      },
      stepIndex: 1,
    })
  }

  React.useEffect(() => {
    if (!watch('qualtricsIntegration')) {
      setValue('qualtricsLink', '')
    }
  }, [setValue, watch])

  return (
    <Container maxWidth="sm">
      <form onSubmit={(e) => e.preventDefault()}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box mb={2} mt={4}>
              <Typography variant="h5" color="textSecondary">
                <Typography component="span" variant="h5" color="primary">
                  #1
                </Typography>
                {'  '}
                Initial Setup
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="topic"
              variant="outlined"
              margin="normal"
              fullWidth
              name="topic"
              label="Topic"
              autoFocus
              required
              inputRef={register({
                required: 'Please enter topic',
              })}
              error={errors.topic}
              inputProps={{ maxLength: 75 }}
              autoComplete="off"
              helperText={
                <Box display="flex" component="span">
                  <Box flexGrow={1} component="span">
                    <Typography variant="caption">{errors.topic?.message}</Typography>
                  </Box>
                  <Box component="span">
                    <Typography variant="caption">{`${watch('topic').length}/ 75 characters`}</Typography>
                  </Box>
                </Box>
              }
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined" margin="normal" error={errors.preferredFunction} required>
              <InputLabel id="select-function">Preferred Function</InputLabel>
              <Controller
                as={
                  <Select labelId="select-function" label="Preferred Function" defaultValue="">
                    <MenuItem value="Quadratic">Quadratic</MenuItem>
                    <MenuItem value="Linear">Linear</MenuItem>
                  </Select>
                }
                control={control}
                rules={{
                  required: 'Please choose survey function',
                }}
                name="preferredFunction"
                defaultValue=""
                disabled={status === 'Published'}
              />
              <FormHelperText>{errors.preferredFunction?.message}</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              id="credits"
              type="number"
              variant="outlined"
              margin="normal"
              fullWidth
              name="credits"
              label="Total number of Credits"
              inputRef={register({
                required: 'Please enter number of credits',
                validate: {
                  positive: (value) => (parseInt(value, 10) > 0 ? undefined : 'Please enter a positive number'),
                  max: (value) => (value <= 999 ? undefined : 'Please enter a number less than 999'),
                },
              })}
              error={errors.credits}
              helperText={errors.credits?.message}
              disabled={status === 'Published'}
              required
            />
          </Grid>

          <Grid item xs={12}>
            {/* TODO: this solution with defaultChecked giving some warnings on console */}
            <FormControlLabel
              inputRef={register}
              control={<Checkbox color="primary" defaultChecked={watch('qualtricsIntegration')} />}
              name="qualtricsIntegration"
              label="Do you want to redirect respondent to Qualtrics?"
            />
          </Grid>

          <Grid item xs={12}>
            <Box hidden={!watch('qualtricsIntegration')}>
              <TextField
                id="qualtricsLink"
                variant="outlined"
                margin="normal"
                fullWidth
                name="qualtricsLink"
                label="Qualtrics survey link"
                inputRef={register({
                  validate: (value) =>
                    watch('qualtricsIntegration') && value.length === 0 ? 'Please enter Qualtrics link' : undefined,
                })}
                error={errors.qualtricsLink}
                helperText={errors.qualtricsLink?.message}
                required
                autoComplete="off"
                InputProps={{
                  endAdornment: (
                    <ClickAwayListener onClickAway={() => setTooltip(false)}>
                      <Tooltip
                        PopperProps={{
                          disablePortal: true,
                        }}
                        onClose={() => setTooltip(false)}
                        open={openTooltip}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        arrow
                        placement="down"
                        title={
                          <Typography variant="body2" component="span">
                            You can find this link on Qualtrics distributions section {'->'} Anonymous Link
                          </Typography>
                        }
                      >
                        <IconButton color="secondary" onClick={() => setTooltip(!openTooltip)}>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </ClickAwayListener>
                  ),
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box mb={2} mt={4}>
              <Typography variant="h5" color="textSecondary">
                <Typography component="span" variant="h5" color="primary">
                  #2
                </Typography>
                {'  '}
                Language Designation
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined" margin="normal" required>
              <InputLabel id="select-language">Preferred Language</InputLabel>
              <Controller
                as={
                  <Select labelId="select-language" label="Preferred Language">
                    <MenuItem value="agree/disagree">Agree/Disagree</MenuItem>
                    <MenuItem value="favor/oppose">Favor/Oppose</MenuItem>
                    <MenuItem value="approve/reject">Approve/Reject</MenuItem>
                    <MenuItem value="aye/nay ">Aye/Nay </MenuItem>
                    <MenuItem value="custom">Custom</MenuItem>
                  </Select>
                }
                control={control}
                name="preferredLanguage"
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined" margin="normal" required>
              <InputLabel id="select-token">Preferred Token</InputLabel>
              <Controller
                as={
                  <Select labelId="select-token" label="Preferred Token">
                    <MenuItem value="Credits">Credits</MenuItem>
                    <MenuItem value="Coins">Coins</MenuItem>
                    <MenuItem value="Tokens">Tokens</MenuItem>
                    <MenuItem value="custom">Custom</MenuItem>
                  </Select>
                }
                control={control}
                name="preferredToken"
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Box hidden={watch('preferredToken') !== 'custom'} width="100%" p={1}>
              <TextField
                id="customPreferredToken"
                variant="outlined"
                margin="normal"
                inputProps={{ maxLength: 12 }}
                required
                fullWidth
                name="customPreferredToken"
                label="Custom Credit Language"
                autoComplete="off"
                helperText={
                  <Box display="flex" component="span">
                    <Box flexGrow={1} component="span">
                      <Typography variant="caption">{errors.customPreferredToken?.message}</Typography>
                    </Box>
                    <Box component="span">
                      <Typography variant="caption">{`${
                        watch('customPreferredToken').length
                      }/ 12 characters`}</Typography>
                    </Box>
                  </Box>
                }
                inputRef={register({
                  validate: (value) =>
                    watch('preferredToken') === 'custom' && value.length === 0
                      ? 'Please enter custom credit language'
                      : undefined,
                })}
                error={errors.customPreferredToken}
              />
            </Box>
          </Grid>

          <Box hidden={watch('preferredLanguage') !== 'custom'} width="100%" p={1}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="thumbsUpLanguage"
                  variant="outlined"
                  margin="normal"
                  inputProps={{ maxLength: 20 }}
                  InputProps={{
                    startAdornment: (
                      <Box mr={1.5}>
                        <ThumbUpIcon />
                      </Box>
                    ),
                  }}
                  fullWidth
                  name="thumbsUpLanguage"
                  label="Thumbs Up Language"
                  autoComplete="off"
                  helperText={
                    <Box display="flex" component="span">
                      <Box flexGrow={1} component="span">
                        <Typography variant="caption">{errors.thumbsUpLanguage?.message}</Typography>
                      </Box>
                      <Box component="span">
                        <Typography variant="caption" noWrap>{`${
                          watch('thumbsUpLanguage').length
                        }/ 20 characters`}</Typography>
                      </Box>
                    </Box>
                  }
                  error={errors.thumbsUpLanguage}
                  inputRef={register({
                    validate: (value) =>
                      watch('preferredLanguage') === 'custom' && value.length === 0
                        ? 'Please enter language for thumbs up'
                        : undefined,
                  })}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="thumbsDownLanguage"
                  variant="outlined"
                  margin="normal"
                  inputProps={{ maxLength: 20 }}
                  InputProps={{
                    startAdornment: (
                      <Box mr={1.5}>
                        <ThumbDownIcon />
                      </Box>
                    ),
                  }}
                  fullWidth
                  name="thumbsDownLanguage"
                  label="Thumbs Down Language"
                  autoComplete="off"
                  helperText={
                    <Box display="flex" component="span">
                      <Box flexGrow={1} component="span">
                        <Typography variant="caption">{errors.thumbsDownLanguage?.message}</Typography>
                      </Box>
                      <Box component="span">
                        <Typography variant="caption" noWrap>{`${
                          watch('thumbsDownLanguage').length
                        }/ 20 characters`}</Typography>
                      </Box>
                    </Box>
                  }
                  error={errors.thumbsDownLanguage}
                  inputRef={register({
                    validate: (value) =>
                      watch('preferredLanguage') === 'custom' && value.length === 0
                        ? 'Please enter language for thumbs down'
                        : undefined,
                  })}
                  required
                />
              </Grid>
            </Grid>
          </Box>

          <Grid item xs={12}>
            <Box mt={5} mb={7} display="flex" justifyContent="space-between">
              <Button variant="outlined" disabled>
                Back
              </Button>
              <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
                Next
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Container>
  )
}

SurveyConfig.propTypes = {
  handleSave: PropTypes.func.isRequired,
  survey: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default SurveyConfig
