import React from 'react'
import { Box, Typography, Button, Backdrop } from '@material-ui/core'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    color: theme.palette.grey[100],
  },
}))

function BackdropWarning({ open, handleOpen, firstTitle, secondTitle, body }) {
  const classes = useStyles()

  return (
    <Backdrop className={classes.backdrop} open={open}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        mx={{ xs: 2, md: 0 }}
        textAlign="center"
      >
        <Typography variant="h4">{firstTitle}</Typography>
        <Typography variant="h4">{secondTitle}</Typography>

        <Box my={3}>
          <Typography variant="body1">{body}</Typography>
        </Box>

        <Button variant="contained" onClick={() => handleOpen(false)}>
          Got it
        </Button>
      </Box>
    </Backdrop>
  )
}

BackdropWarning.propTypes = {
  open: PropTypes.bool.isRequired,
  handleOpen: PropTypes.func.isRequired,
  firstTitle: PropTypes.string,
  secondTitle: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
}

BackdropWarning.defaultProps = {
  firstTitle: 'Oh No!',
}

export default React.memo(BackdropWarning)
