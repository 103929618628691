import { client } from 'util/api-client'

function createSurvey(newSurvey) {
  return client('createSurvey', { body: newSurvey }).then((survey) => survey)
}

function getSurveys() {
  return client('surveys').then((surveys) => surveys)
}

function getSurvey(surveyId) {
  return client(`survey/${surveyId}`).then((survey) => survey)
}

function createAnswer(newAnswer) {
  return client('createAnswer', { body: newAnswer }).then((answer) => answer)
}

function getAnswers(surveyId) {
  return client(`answers/${surveyId}`).then((answers) => answers)
}

function deleteSurvey(surveyId) {
  return client(`survey/${surveyId}`, { method: 'DELETE' }).then((survey) => survey)
}

function publishSurvey(surveyId) {
  return client(`publishSurvey/${surveyId}`).then((survey) => {
    return survey
  })
}

function finishSurvey(surveyId) {
  return client(`finishSurvey/${surveyId}`).then((survey) => survey)
}

function createFAQ(FAQlist) {
  return client('createFAQ', { body: FAQlist })
}

function getFAQ() {
  return client('getFAQ')
}

export {
  createSurvey,
  getSurveys,
  getSurvey,
  createAnswer,
  getAnswers,
  deleteSurvey,
  publishSurvey,
  finishSurvey,
  createFAQ,
  getFAQ,
}
