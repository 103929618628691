import React from 'react'
import { Box, Container, Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import Preview from 'components/Survey/components/Preview'

const SurveyPreview = ({ survey, handleBack, isLoading, handleCreateSurvey }) => (
  <>
    <Preview survey={survey} />

    <Container maxWidth="sm">
      <Box mt={5} mb={7} display="flex" justifyContent="space-between">
        <Button variat="outlined" onClick={handleBack}>
          Back
        </Button>

        <Button variant="contained" color="primary" onClick={handleCreateSurvey} disabled={isLoading}>
          Save
        </Button>
      </Box>
    </Container>
  </>
)

SurveyPreview.propTypes = {
  survey: PropTypes.objectOf(PropTypes.any).isRequired,
  handleBack: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleCreateSurvey: PropTypes.func.isRequired,
}

export default SurveyPreview
