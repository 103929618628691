import { createMuiTheme } from '@material-ui/core'

const palette = {
  type: 'light',
  background: {
    default: '#eee',
  },
  primary: {
    light: '#5ddef4',
    main: '#00acc1',
    dark: '#007c91',
    contrastText: '#FFF',
  },
  secondary: {
    light: '#ff7961',
    main: '#f44336',
    dark: '#ba000d',
    contrastText: '#FFF',
  },
  grey: {
    50: '#FAFAFA',
    100: '#F5F5F5',
    200: '#EEEEEE',
    300: '#E0E0E0',
    400: '#BDBDBD',
    500: '#9E9E9E',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
  },
}

export default createMuiTheme({
  palette,
  status: {
    pilot: '#03A9F4',
    published: '#03AD00',
    finished: '#FF5722',
    downVote: '#d92300',
    upVote: '#00b649',
    copied: '#7CE073',
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      'Karla',
    ].join(','),
  },
  overrides: {
    MuiStepper: {
      root: {
        backgroundColor: 'transparent',
      },
    },
    MUIRichTextEditor: {
      root: {
        backgroundColor: 'transparent',
      },
      container: {
        display: 'flex',
        flexDirection: 'column-reverse',
      },
      editor: {
        backgroundColor: 'transparent',
        padding: '20px',
        height: '200px',
        maxHeight: '200px',
        overflow: 'auto',
        border: `1px solid ${palette.grey[400]}`,
        borderRadius: '4px',
        '&:hover': {
          borderColor: '#1f1f1f',
        },
        '&:focus': {
          // NOT WORKING
          borderColor: palette.primary.main,
        },
      },
      toolbar: {
        marginTop: '8px',
        backgroundColor: 'transparent',
      },
      placeHolder: {
        paddingLeft: 20,
        width: '100%',
        height: '150px',
        position: 'absolute',
        top: '20px',
      },
      anchorLink: {
        color: palette.primary.main,
        textDecoration: 'underline',
      },
    },
  },
})
