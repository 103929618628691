import { client, setToken, localStorageKey, removeToken } from 'util/api-client'

function getUser() {
  const token = getToken()

  if (!token) {
    return Promise.resolve(null)
  }

  return client('user').then(({ userCredentials }) => userCredentials)
}

function login({ email, password }) {
  return client('login', { body: { email, password } })
    .then(setToken)
    .then(() => client('user'))
    .then(({ userCredentials }) => userCredentials)
}

function logout() {
  return client('logout').then(() => removeToken())
}

function resetPassword({ newPassword, password }) {
  return client('resetPassword', { body: { password, newPassword } }).then((user) => user)
}

function resetPasswordEmail(email) {
  return client('resetPasswordEmail', { body: { email } }).then((user) => user)
}

function createUser({ firstName, lastName, email, password }) {
  return client('signup', {
    body: { firstName, lastName, email, password },
  }).then((data) => data)
}

function getToken() {
  return window.localStorage.getItem(localStorageKey)
}

function isLoggedIn() {
  return Boolean(getToken())
}

export { login, logout, createUser, getToken, getUser, isLoggedIn, resetPassword, resetPasswordEmail }
