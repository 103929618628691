import React from 'react'
import { Container, Typography, Box } from '@material-ui/core'
import PropTypes from 'prop-types'
import useRichText from 'util/use-rich-text'
import TextWrapper from 'components/TextWrapper'
import RichTextViewer from 'components/RichTextViewer'
import QuestionsForm from '../../steps/Questions'
import useMethod from '../../methods'

const Preview = ({ survey }) => {
  const { availableCredits, questions, vote, canVote } = useMethod(survey)
  const [welcomeMessageHTML, setWelcomeMessageHTML] = React.useState(null)
  const [completionMessageHTML, setCompletionMessageHTML] = React.useState(null)
  const [isWelcomeMessageExist] = useRichText({ field: survey.setup.welcomeMessage })
  const [isCompletionMessageExist] = useRichText({ field: survey.setup.completionMessage })
  const {
    topic,
    preferredToken,
    credits,
    customPreferredToken,
    preferredFunction,
    thumbsDownLanguage,
    thumbsUpLanguage,
    qualtricsLink,
    qualtricsIntegration,
  } = survey.config

  function getPreferredToken() {
    return preferredToken !== 'custom' ? preferredToken : customPreferredToken
  }

  React.useEffect(() => {
    if (isWelcomeMessageExist) {
      setWelcomeMessageHTML(survey.setup.welcomeMessage)
    }
  }, [isWelcomeMessageExist, survey.setup.welcomeMessage])

  React.useEffect(() => {
    if (isCompletionMessageExist) {
      setCompletionMessageHTML(survey.setup.completionMessage)
    }
  }, [isCompletionMessageExist, survey.setup.completionMessage])

  return (
    <Box mb={5}>
      <Container maxWidth="sm">
        <Box textAlign="center" my={3}>
          <Typography variant="h4">Survey Preview</Typography>
        </Box>

        <TextWrapper my={2} alignItems="start">
          <Typography variant="body1" color="textSecondary">
            Topic:{' '}
            <Typography component="span" color="secondary">
              {topic}
            </Typography>
          </Typography>

          <Typography variant="body1" color="textSecondary">
            Preferred Function:{' '}
            <Typography component="span" color="secondary">
              {preferredFunction}
            </Typography>
          </Typography>

          <Typography variant="body1" color="textSecondary">
            {getPreferredToken()}:{' '}
            <Typography component="span" color="secondary">
              {credits}
            </Typography>
          </Typography>

          <Typography variant="body1" color="textSecondary">
            Preferred Language:{' '}
            <Typography component="span" color="secondary">
              {`${thumbsUpLanguage}/${thumbsDownLanguage}`}
            </Typography>
          </Typography>

          {qualtricsIntegration && (
            // Not sure why this text align start
            <Box textAlign="start">
              <Typography variant="body1" color="textSecondary">
                Qualtrics link:{' '}
                <Typography component="span" color="secondary">
                  {qualtricsLink}
                </Typography>
              </Typography>
            </Box>
          )}
        </TextWrapper>

        <TextWrapper alignItems="start" textAlign="start" mb={2}>
          {welcomeMessageHTML ? (
            <RichTextViewer initialValue={welcomeMessageHTML} />
          ) : (
            <Typography>This survey has no welcome message</Typography>
          )}
        </TextWrapper>

        <TextWrapper alignItems="start" textAlign="start">
          <Typography variant="h5">Questions</Typography>
          <Box mb={2}>
            <Typography variant="body1" color="textSecondary">
              {getPreferredToken()}:{' '}
              <Typography component="span" color="secondary">
                {availableCredits}
              </Typography>
            </Typography>
          </Box>

          <QuestionsForm
            questions={questions}
            surveyId={survey.surveyId}
            researcherId={survey.researcherId}
            vote={vote}
            canVote={canVote}
            config={survey.config}
            availableCredits={availableCredits}
            status={survey.status}
            previewMode
          />
        </TextWrapper>

        <TextWrapper alignItems="start" textAlign="start" mt={2}>
          {completionMessageHTML ? (
            <RichTextViewer initialValue={completionMessageHTML} />
          ) : (
            <Typography>This survey has no completion message</Typography>
          )}
        </TextWrapper>
      </Container>
    </Box>
  )
}

Preview.propTypes = {
  survey: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default Preview
