import React from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Box,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  deleteBtn: {
    color: theme.palette.getContrastText(theme.palette.error.dark),
    backgroundColor: theme.palette.error.dark,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}))
const ActionConfirmDialog = ({ open, handleClose, action, confirmText, title, buttonText }) => {
  const classes = useStyles()

  function handleClick() {
    action()
    handleClose()
  }

  return (
    <Box>
      <Dialog onClose={() => handleClose()} aria-labelledby="delete-survey" open={open} maxWidth="xs">
        <DialogTitle id="delete-survey" disableTypography className={classes.title}>
          <Typography variant="h6">{title} Survey</Typography>

          <IconButton aria-label="close" className={classes.closeButton} onClick={() => handleClose()}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="body1">{confirmText}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" className={classes.deleteBtn} onClick={handleClick} fullWidth>
            {buttonText}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

ActionConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  action: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  confirmText: PropTypes.string,
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
}

ActionConfirmDialog.defaultProps = {
  buttonText: 'Confirm',
  confirmText: '',
}

export default ActionConfirmDialog
