import React from 'react'
import MUIRichTextEditor from 'mui-rte'
import { convertToRaw } from 'draft-js'
import { Box, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'

function RichTextEditor({ handleChange, initialValue, maxLength, placeholder }) {
  const [count, setCount] = React.useState(0)
  const onChange = (state) => {
    const contentState = state.getCurrentContent()
    const { length } = contentState.getPlainText()
    const text = JSON.stringify(convertToRaw(state.getCurrentContent()))

    setCount(length)
    handleChange(text)
  }

  const CharCount = () => {
    return (
      <Box position="absolute" right={0} display="inline-flex">
        <Typography variant="caption" color="textSecondary">
          {count}/{maxLength}
        </Typography>
      </Box>
    )
  }

  return (
    <MUIRichTextEditor
      controls={['title', 'bold', 'italic', 'undo', 'redo', 'numberList', 'bulletList', 'char-count', 'link']}
      defaultValue={initialValue}
      onChange={onChange}
      maxLength={maxLength}
      label={placeholder}
      customControls={[
        {
          name: 'char-count',
          component: CharCount,
          type: 'block',
        },
      ]}
      inlineToolbar
    />
  )
}

RichTextEditor.propTypes = {
  handleChange: PropTypes.func.isRequired,
  initialValue: PropTypes.string,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
}

RichTextEditor.defaultProps = {
  maxLength: 2000,
  placeholder: 'Start typing...',
  initialValue: '',
}

export default RichTextEditor
