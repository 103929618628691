import React from 'react'
import bootstrapAppData from 'util/bootstrap'
import * as authClient from 'util/auth-client'
import useAsync from 'util/use-async'
import ProgressBar from 'components/ProgressBar'
import { hasToken } from 'util/api-client'
import ReAuthenticatePage from 'pages/ReAuthenticate'

const AuthContext = React.createContext()

AuthContext.displayName = 'AuthContext'

function AuthProvider(props) {
  const { data, isLoading, isIdle, isError, isSuccess, run, setData } = useAsync()

  React.useLayoutEffect(() => {
    run(bootstrapAppData())
  }, [run])

  const login = React.useCallback(
    (form) => {
      return authClient.login(form).then((user) => setData({ user }))
    },
    [setData],
  )
  const createUser = React.useCallback((form) => {
    return authClient.createUser(form)
  }, [])
  const logout = React.useCallback(() => {
    authClient.logout()
    setData(null)
  }, [setData])
  const user = data?.user
  const value = React.useMemo(() => ({ user, login, logout, createUser }), [login, logout, createUser, user])

  if (isLoading || isIdle) {
    return <ProgressBar active />
  }

  if (isError) {
    if (hasToken()) {
      logout()
    } else {
      return <ReAuthenticatePage />
    }
  }

  if (isSuccess) {
    return <AuthContext.Provider value={value} {...props} />
  }

  throw new Error(`Unhandled status`)
}

function useAuth() {
  const context = React.useContext(AuthContext)

  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }

  return context
}

export { AuthProvider, useAuth }
