import React from 'react'
import ReactDOM from 'react-dom'
import { UserPreferencesProvider } from 'context/user-preferences-context'
import App from './components/App'

ReactDOM.render(
  <UserPreferencesProvider>
    <App />
  </UserPreferencesProvider>,
  document.getElementById('root'),
)
