import React from 'react'
import PropTypes from 'prop-types'

const Linear = (survey) => {
  const { credits, preferredFunction } = survey.config
  const [availableCredits, setAvailableCredits] = React.useState(parseInt(credits))
  const [questions, setQuestions] = React.useState([])

  React.useEffect(() => {
    const newQuestions = survey.setup.questions
      .map((question) => ({
        label: question.value,
        vote: 0,
        id: question.id,
      }))
      .sort(() => 0.5 - Math.random())

    setQuestions(newQuestions)
  }, [preferredFunction, survey])

  React.useEffect(() => {
    if (questions) {
      const totalCost = questions.reduce((cost, q) => (q.vote < 0 ? cost + q.vote * -1 : cost + q.vote), 0)

      setAvailableCredits(credits - totalCost)
    }
  }, [questions, credits])

  function canVote(index, number) {
    const totalCost = questions.reduce(
      (cost, q, i) => (index === i ? cost + Math.abs(q.vote + number) : cost + Math.abs(q.vote)),
      0,
    )

    return totalCost <= credits
  }

  function vote(index, number) {
    if (canVote(index, number)) {
      setQuestions(questions.map((q, i) => (i === index ? { ...q, vote: q.vote + number } : q)))
    }
  }

  return { availableCredits, questions, vote, canVote }
}

Linear.propTypes = {
  survey: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default Linear
