import React from 'react'
import { ThemeProvider } from '@material-ui/styles'
import MUIRichTextEditor from 'mui-rte'
import viewerTheme from 'theme/richTextViewer'
import PropTypes from 'prop-types'

function RichTextViewer({ initialValue }) {
  return (
    <ThemeProvider
      theme={(outerTheme) => ({
        ...viewerTheme,
        ...outerTheme,
        overrides: {
          ...outerTheme.overrides,
          ...viewerTheme.overrides,
        },
      })}
    >
      <MUIRichTextEditor defaultValue={initialValue} readOnly toolbar={false} />
    </ThemeProvider>
  )
}

RichTextViewer.propTypes = {
  initialValue: PropTypes.string.isRequired,
}

export default RichTextViewer
