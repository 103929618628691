import { createMuiTheme } from '@material-ui/core'

export default createMuiTheme({
  overrides: {
    MUIRichTextEditor: {
      root: {
        marginTop: 20,
        width: '100%',
      },
      editorContainer: {
        cursor: 'default',
        justifyContent: 'center',
        display: 'flex',
      },
    },
  },
})
