import React from 'react'
import { SharedContext } from 'context/shared-context'
import { LinearProgress, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  progressBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
}))
const ProgressBar = ({ active = false, classes }) => {
  const progressClasses = useStyles()
  const { shared } = React.useContext(SharedContext)

  return (
    <Box position="relative">
      {(shared?.inProgress || active) && (
        <Box position="absolute" top={0} width="100%">
          <LinearProgress color="secondary" className={progressClasses.progressBar} classes={classes} />
        </Box>
      )}
    </Box>
  )
}

ProgressBar.propTypes = {
  active: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.any),
}

ProgressBar.defaultProps = {
  active: false,
  classes: {},
}

export default ProgressBar
