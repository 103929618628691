import React from 'react'
import { Box, IconButton, Typography } from '@material-ui/core'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import { makeStyles } from '@material-ui/core/styles'
import BackdropWarning from 'components/BackdropWarning'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  downVote: {
    color: ({ isVoteDownDisabled }) => (isVoteDownDisabled ? theme.palette.grey[500] : theme.status.downVote),
  },
  upVote: {
    color: ({ isVoteUpDisabled }) => (isVoteUpDisabled ? theme.palette.grey[500] : theme.status.upVote),
  },
  bigBall: {
    border: `1px solid ${theme.palette.grey[700]}`,
    width: ({ voteCircle }) => voteCircle,
    borderRadius: ({ voteCircle }) => voteCircle / 2,
    height: ({ voteCircle }) => voteCircle,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  smallBall: {
    width: ({ progress }) => progress,
    height: ({ progress }) => progress,
    borderRadius: ({ progress }) => progress / 2,
    background: ({ voteDirection }) => {
      if (voteDirection === 0) {
        return 'transparent'
      }

      return voteDirection > 0 ? theme.status.upVote : theme.status.downVote
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: ({ voteDirection }) => (voteDirection === 0 ? theme.palette.text.primary : 'white'),
  },
  credits: {
    background: theme.palette.primary.main,
    width: '45px',
    borderRadius: '5px',
    justifyContent: 'center',
    display: 'flex',
  },
}))
const Vote = ({
  question,
  vote,
  canVote,
  questionIndex,
  thumbsUpLanguage,
  thumbsDownLanguage,
  preferredToken,
  totalCredits,
  preferredFunction,
}) => {
  const voteCircle = 100
  const [progress, setProgress] = React.useState(0)
  const max = getMaxVotes()
  const isVoteDownDisabled = !canVote(questionIndex, -1)
  const isVoteUpDisabled = !canVote(questionIndex, 1)
  const [openWarning, setOpenWarning] = React.useState(false)
  const classes = useStyles({
    progress,
    voteDirection: question.vote,
    voteCircle,
    isVoteDownDisabled,
    isVoteUpDisabled,
  })

  function handleVote(voteDirection) {
    if ((voteDirection < 0 && isVoteDownDisabled) || (voteDirection > 0 && isVoteUpDisabled)) {
      setOpenWarning(true)
    } else {
      const progressPerVote = voteCircle / (max === 0 ? 1 : max)
      const voteProgress = question.vote + voteDirection
      const p = voteProgress < 0 ? voteProgress * progressPerVote * -1 : voteProgress * progressPerVote

      setOpenWarning(false)
      vote(questionIndex, voteDirection)
      setProgress(p < 30 ? 30 : p)
    }
  }

  function getMaxVotes() {
    if (preferredFunction === 'Quadratic') {
      return Math.floor(Math.sqrt(totalCredits))
    }

    return totalCredits
  }

  function getCreditSpent() {
    if (preferredFunction === 'Quadratic') {
      return Math.abs(question.vote) * Math.abs(question.vote)
    }

    return Math.abs(question.vote)
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center" my={2}>
      <Box display="flex" flexDirection="column">
        <Box minWidth={58}>
          <IconButton className={classes.downVote} onClick={() => handleVote(-1)} aria-label="thumb down">
            <ThumbDownIcon fontSize="large" />
          </IconButton>
        </Box>
        <Typography variant="h6" color={isVoteDownDisabled ? 'textSecondary' : 'textPrimary'}>
          {thumbsDownLanguage}
        </Typography>
      </Box>

      <Box mx={5} display="flex" flexDirection="column" alignItems="center">
        <Box className={classes.bigBall}>
          <Box className={classes.smallBall}>
            <Typography variant="h6">{question.vote < 0 ? question.vote * -1 : question.vote}</Typography>
          </Box>
        </Box>
        <Box display="flex" justifyContent="center" mt={1} mb={0.5}>
          <Box className={classes.credits} mr={1}>
            <Typography variant="h6" color="textSecondary">
              {getCreditSpent()}
            </Typography>
          </Box>
          <Typography variant="h6">{preferredToken}</Typography>
        </Box>
      </Box>

      <Box display="flex" flexDirection="column">
        <Box>
          <IconButton className={classes.upVote} onClick={() => handleVote(1)} aria-label="thumb up">
            <ThumbUpIcon fontSize="large" />
          </IconButton>
        </Box>
        <Typography variant="h6" color={isVoteUpDisabled ? 'textSecondary' : 'textPrimary'}>
          {thumbsUpLanguage}
        </Typography>
      </Box>

      <BackdropWarning
        open={openWarning}
        handleOpen={setOpenWarning}
        secondTitle="Looks like you ran out of credits."
        body="You will need to reduce some of your votes if you want more credits."
      />
    </Box>
  )
}

Vote.propTypes = {
  question: PropTypes.objectOf(PropTypes.any).isRequired,
  vote: PropTypes.func.isRequired,
  canVote: PropTypes.func.isRequired,
  questionIndex: PropTypes.number.isRequired,
  thumbsUpLanguage: PropTypes.string.isRequired,
  thumbsDownLanguage: PropTypes.string.isRequired,
  preferredToken: PropTypes.string.isRequired,
  totalCredits: PropTypes.string.isRequired,
  preferredFunction: PropTypes.string.isRequired,
}

export default Vote
