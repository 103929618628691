import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { ThemeProvider, StylesProvider, createGenerateClassName } from '@material-ui/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import AppProviders from 'context'
import { UserPreferencesContext } from 'context/user-preferences-context'
import themes from 'theme'
import ErrorFallback from 'components/ErrorFallback'
import ProgressBar from 'components/ProgressBar'
import Routes from './components/Routes'

const App = () => {
  const { preferences } = React.useContext(UserPreferencesContext)
  const generateClassName = createGenerateClassName({
    productionPrefix: 'qvsr',
    seed: `${Math.random().toString(36).substring(2)}-${preferences.theme}`,
  })

  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={themes[preferences.theme]}>
        <CssBaseline />

        <AppProviders>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <React.Suspense fallback={<ProgressBar />}>
              <React.StrictMode>
                <Routes />
              </React.StrictMode>
            </React.Suspense>
          </ErrorBoundary>
        </AppProviders>
      </ThemeProvider>
    </StylesProvider>
  )
}

export default App
