import React from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(1, 3, 3, 3),
    border: `1px solid ${theme.palette.grey[700]}`,
    borderRadius: 15,
    display: 'flex',
    alignItems: ({ alignItems }) => alignItems,
    textAlign: ({ textAlign }) => textAlign,
    flexDirection: 'column',
  },
}))
const InsightWrapper = ({ children, alignItems, textAlign, ...props }) => {
  const classes = useStyles({ alignItems, textAlign })

  return (
    <Box className={classes.wrapper} {...props}>
      {children}
    </Box>
  )
}

InsightWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  alignItems: PropTypes.string,
  textAlign: PropTypes.string,
}

InsightWrapper.defaultProps = {
  alignItems: 'center',
  textAlign: 'center',
}

export default InsightWrapper
