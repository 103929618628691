import { convertFromRaw } from 'draft-js'

function useRichText({ field }) {
  function isEmpty() {
    if (field !== '') {
      const state = convertFromRaw(JSON.parse(field))

      return state.hasText()
    }

    return null
  }

  const isFieldEmpty = isEmpty()

  return [isFieldEmpty]
}

export default useRichText
