import React, { createContext, useReducer } from 'react'
import PropTypes from 'prop-types'

const initialState = {
  inProgress: false,
  snack: {
    open: false,
    message: '',
    status: '',
  },
  filter: {
    published: false,
    pilot: false,
    finished: false,
  },
  sort: {
    order: 'des',
    field: 'topic',
  },
}
const SharedContext = createContext(initialState)
const sharedReducer = (state, { type, value }) => {
  switch (type) {
    case 'progress':
      return {
        ...state,
        inProgress: value,
      }

    case 'snack':
      return {
        ...state,
        snack: { ...value, open: true },
      }

    case 'closeSnack':
      return {
        ...state,
        snack: {
          open: false,
          message: '',
          status: '',
        },
      }

    case 'setFilter': {
      return {
        ...state,
        filter: {
          ...state.filter,
          [value.name]: value.checked,
        },
      }
    }

    case 'resetFilter':
      return {
        ...state,
        filter: initialState.filter,
      }

    case 'sort':
      return {
        ...state,
        sort: value,
      }

    default:
      return state
  }
}

const SharedProvider = ({ children }) => {
  const [shared, dispatch] = useReducer(sharedReducer, initialState)
  const value = { shared, dispatch }

  return <SharedContext.Provider value={value}>{children}</SharedContext.Provider>
}

SharedProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

const SharedContexConsumer = SharedContext.Consumer

export { sharedReducer, SharedContext, SharedProvider, SharedContexConsumer }
